import React, { useEffect, useState } from "react";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UnlockOutlined,
  UserOutlined,
  ReloadOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Col,
  Input,
  Layout,
  Row,
  Table,
  Button,
  Drawer,
  Descriptions,
  DatePicker,
  ConfigProvider,
} from "antd";
import authAxios_v2 from "../../../api/axiosClient_v2";
import axios from "axios";
import {
  openNotificationWithIcon,
  formatDate,
  disabledDates,
} from "../../../Function";
import { TinyColor } from "@ctrl/tinycolor";
import dayjs from "dayjs";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const colors1 = ["#6253E1", "#04BEFE"];
const colors2 = ["#fc6076", "#ff9a44", "#ef9d43", "#e75516"];
const colors3 = ["#40e495", "#30dd8a", "#2bb673"];
const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const localhost = "https://hr.rainscales.com.vn";
// const localhost = "http://171.244.133.113";
const loginURL = `${localhost}/api/token/`;

const logoutURL = `${localhost}/api/logout/`;
const logmsURL = `${localhost}/api/logms-list/`;
const logmsExtractURL = `${localhost}/api/logms-extract/`;
const ViewHRV2 = () => {
  const [data, setData] = useState([]);
  const [pager, setPager] = useState({
    page_size: 50,
    count: 0,
    page: 1,
  });

  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [searchDetail, setSearchDetail] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [searchPath, setSearchPath] = useState("");
  const [searchMsg, setSearchMsg] = useState("");
  const [dataDetail, setDataDetail] = useState();
  const [openDetail, setOpenDetail] = useState(false);
  const [dates, setDates] = useState([dayjs(), dayjs()]);

  const logout_new = (token) => {
    authAxios_v2(token)
      .get(logoutURL)
      .then((res) => {
        setToken("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postLogin = () => {
    if (userName !== "" && passWord !== "") {
      axios
        .post(loginURL, {
          username: userName,
          password: passWord,
        })
        .then((res) => {
          const token = res.data.access;
          const refresh = res.data.refresh;
          setToken(token);
        })
        .catch((err) => {
          setLoading(false);
          if ("response" in err) {
            openNotificationWithIcon("error", err.response.data.errorMessage);
            logout_new(token);
            return;
          }
          if (err.code) {
            openNotificationWithIcon("error", err.code);
            logout_new(token);
            return;
          }
        });
    } else {
      openNotificationWithIcon(
        "warning",
        "Please enter your login information."
      );
    }
  };

  const fetchLogs = (params = {}) => {
    if (token != "" && token != undefined) {
      authAxios_v2(token)
        .get(logmsURL, { params })
        .then((res) => {
          setData(res.data.results);
          setPager({
            page: params.page,
            page_size: params.page_size,
            count: res.data.count,
          });
        })
        .catch((err) => {
          if ("response" in err) {
            openNotificationWithIcon("error", err.response.data.message);
            logout_new(token);
            return;
          }
          if (err.code) {
            openNotificationWithIcon("error", err.code);
            logout_new(token);
            return;
          }
        });
    }
  };

  useEffect(() => {
    fetchLogs({
      search_username: searchUsername,
      search_path: searchPath,
      search_msg: searchMsg,
      search_detail: searchDetail,
      page: pager.page,
      page_size: pager.page_size,
      from_create_date: dates[0].format("YYYY-MM-DD"),
      to_create_date: dates[1].format("YYYY-MM-DD"),
    });
  }, [token]);

  const onChangUsername = (event) => {
    setUserName(event.target.value);
  };
  const onChangPassword = (event) => {
    setPassWord(event.target.value);
  };

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.page = pagination.current;
    currentPager.page_size = pagination.pageSize;
    setPager({ ...currentPager });
    fetchLogs({
      search_username: searchUsername,
      search_path: searchPath,
      search_msg: searchMsg,
      search_detail: searchDetail,
      page: pagination.current,
      page_size: pagination.pageSize,
      from_create_date: dates[0].format("YYYY-MM-DD"),
      to_create_date: dates[1].format("YYYY-MM-DD"),
    });
  };

  const openModalDetail = (record) => {
    setDataDetail(record);
    setOpenDetail(true);
  };

  const reloadLog = () => {
    if (token == "" || token == undefined) {
      postLogin();
    }
    setSearchUsername("");
    setSearchPath("");
    setSearchMsg("");
    setSearchDetail("");
    setDates([dayjs(), dayjs()]);
    fetchLogs({
      page: 1,
      page_size: pager.page_size,
      from_create_date: dayjs().format("YYYY-MM-DD"),
      to_create_date: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handlSearch = () => {
    if (token == "" || token == undefined) {
      postLogin();
    }
    fetchLogs({
      search_username: searchUsername,
      search_path: searchPath,
      search_msg: searchMsg,
      search_detail: searchDetail,
      page: 1,
      page_size: pager.page_size,
      from_create_date: dates[0].format("YYYY-MM-DD"),
      to_create_date: dates[1].format("YYYY-MM-DD"),
    });
  };

  const downloadLog = () => {
    const fnFileDownload = require("js-file-download");
    const filename =
      "log_activity_" + dayjs(new Date()).format("DDMMYYYY") + ".json";
    authAxios_v2(token)
      .post(
        logmsExtractURL,
        {
          search_username: searchUsername,
          search_path: searchPath,
          search_msg: searchMsg,
          search_detail: searchDetail,
          from_create_date: dates[0].format("YYYY-MM-DD"),
          to_create_date: dates[1].format("YYYY-MM-DD"),
        },
        { responseType: "blob" }
      )
      .then((res) => {
        fnFileDownload(res.data, filename);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: 80,
      render: (value, item, index) =>
        index + 1 + (pager.page - 1) * pager.page_size,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      ellipsis: true,
      width: 80,
    },
    {
      title: "Action Time",
      dataIndex: "action_time",
      key: "action_time",
      width: 230,
      render: (value, item, index) => formatDate(value),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 150,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 100,
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      width: 100,
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status_code",
      key: "status_code",
      width: 80,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => {
        return (
          <div
            style={{
              columnGap: "1.5ch",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              onClick={() => openModalDetail(record)}
              icon={<EyeOutlined />}
            ></Button>
          </div>
        );
      },
    },
  ];

  const columnsDetail = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      width: 150,
    },
    {
      title: "Handlers Log",
      dataIndex: "handlers_log",
      key: "handlers_log",
    },
    {
      title: "Line No",
      dataIndex: "line_no",
      key: "line_no",
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: "Client IP",
      dataIndex: "client_ip",
      key: "client_ip",
    },
  ];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout className="site-layout">
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <Row style={{ margin: "0% 1%" }}>
            <Col span={8} style={{ textAlign: "start" }}>
              <img
                src={`${process.env.PUBLIC_URL}/RS_Logo.png`}
                alt=""
                style={{ height: 62 }}
              ></img>
            </Col>
            <Col span={16} style={{ display: "flex", alignItems: "center" }}>
              <Row
                style={{
                  width: "100%",
                  columnGap: "3ch",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Input
                  value={userName}
                  prefix={<UserOutlined />}
                  onChange={(event) => onChangUsername(event)}
                  placeholder="Username"
                  style={{ width: "25%" }}
                  // defaultValue={"spadmin_vac"}
                  // autoFocus={focusInput}
                  // status={checkError === true ? "error" : null}
                />
                <Input.Password
                  value={passWord}
                  prefix={<UnlockOutlined />}
                  placeholder="Password"
                  onChange={(event) => onChangPassword(event)}
                  iconRender={(visible) =>
                    visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                  // defaultValue={"Vbpo@12345"}
                  style={{ width: "25%" }}
                  // status={checkError === true ? "error" : null}
                />
                <Button type="primary" onClick={() => postLogin()}>
                  Login
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ margin: "1%" }}>
            <Col span={18} style={{ display: "flex", columnGap: "1.5ch" }}>
              <Col span={7}>
                <RangePicker
                  disabledDate={(e) => disabledDates(e, dates)}
                  onCalendarChange={(val) => {
                    setDates(val);
                  }}
                  value={dates}
                  locale={{
                    lang: {
                      locale: "en_US",
                      rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                      yearFormat: "YYYY",
                      shortMonths: [
                        "Tháng 1",
                        "Tháng 2",
                        "Tháng 3",
                        "Tháng 4",
                        "Tháng 5",
                        "Tháng 6",
                        "Tháng 7",
                        "Tháng 8",
                        "Tháng 9",
                        "Tháng 10",
                        "Tháng 11",
                        "Tháng 12",
                      ],
                      shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                      ok: "OK",
                      monthBeforeYear: true,
                    },
                  }}
                  style={{ width: "100%" }}
                  format={"DD-MM-YYYY"}
                  allowClear={false}
                />
              </Col>
              <Col span={4}>
                <Input
                  placeholder="Search Username"
                  onChange={(e) => setSearchUsername(e.target.value)}
                ></Input>
              </Col>
              <Col span={4}>
                <Input
                  placeholder="Search Path"
                  onChange={(e) => setSearchPath(e.target.value)}
                ></Input>
              </Col>
              <Col span={4}>
                <Input
                  placeholder="Search Message"
                  onChange={(e) => setSearchMsg(e.target.value)}
                ></Input>
              </Col>
              <Col span={4}>
                <Input
                  placeholder="Search Detail"
                  onChange={(e) => setSearchDetail(e.target.value)}
                ></Input>
              </Col>
            </Col>
            <Col span={6}>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg, ${colors1.join(
                          ", "
                        )})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(
                          colors1
                        ).join(", ")})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(
                          colors1
                        ).join(", ")})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button
                    type="primary"
                    onClick={handlSearch}
                    // style={{ width: 200, height: 60 }}
                    icon={<SearchOutlined />}
                  >
                    Search
                  </Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg, ${colors2.join(
                          ", "
                        )})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(
                          colors2
                        ).join(", ")})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(
                          colors2
                        ).join(", ")})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button
                    type="primary"
                    onClick={reloadLog}
                    icon={<ReloadOutlined />}
                    style={{ marginLeft: "5px" }}
                  >
                    Refresh
                  </Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: `linear-gradient(90deg, ${colors3.join(
                          ", "
                        )})`,
                        colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(
                          colors3
                        ).join(", ")})`,
                        colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(
                          colors3
                        ).join(", ")})`,
                        lineWidth: 0,
                      },
                    },
                  }}
                >
                  <Button
                    // onClick={reloadLog}
                    type="primary"
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: "5px" }}
                    onClick={downloadLog}
                  >
                    Extract
                  </Button>
                </ConfigProvider>
              </Col>
            </Col>
          </Row>
          <Row style={{ margin: "1%" }}>
            <Col>
              <Table
                columns={columns}
                dataSource={data}
                onChange={handleChange}
                scroll={{ y: "65vh" }}
                pagination={{
                  current: pager.page,
                  pageSize: pager.page_size,
                  total: pager.count,
                  pageSizeOptions: ["50", "100", "150", "200"],
                }}
              />
              {openDetail && (
                <Drawer
                  // closable
                  // destroyOnClose
                  title={`Detail Logs`}
                  closable={true}
                  placement="right"
                  open={openDetail}
                  // loading={loading}
                  onClose={() => setOpenDetail(false)}
                  width={1000}
                >
                  {dataDetail != undefined && (
                    <Descriptions title="" bordered column={1}>
                      {columnsDetail.map((col) => (
                        <Descriptions.Item
                          label={col.title}
                          key={col.dataIndex}
                          labelStyle={{ width: 200 }}
                        >
                          {[dataDetail].map((itemD) => (
                            <div key={itemD.key}>{itemD[col.dataIndex]}</div>
                          ))}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  )}
                </Drawer>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ViewHRV2;
