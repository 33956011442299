import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import VAC from "../Pages/VAC/ViewVAC";
import ViewIWAKI from "../Pages/IWAKI/ViewIWAKI";
import ViewHRV2 from "../Pages/HR-V2/ViewHRV2";
import ViewQLCT from "../Pages/QLCT/ViewQLCT";
import ViewMEETINGROOM from "../Pages/MEETINGROOM/ViewMEETINGROOM";
import HomeLog from "../Pages/HomeLog";

const cookies = new Cookies();

function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeLog />} />
        <Route path="/vac-v2" element={<VAC />} />
        <Route path="/iwaki" element={<ViewIWAKI />} />
        <Route path="/hr-v2" element={<ViewHRV2 />} />
        <Route path="/qlct" element={<ViewQLCT />} />
        <Route path="/meetingroom" element={<ViewMEETINGROOM />} />
        {/* <Route path="/homepage" element={<ViewMEETINGROOM />} /> */}

      </Routes>
    </Router>
  );
}

export default Main;
