import React from "react";
import { Layout, Typography, Button, Card, Row, Col } from "antd";
import { openNotificationWithIcon } from "../../Function";
const { Header, Content } = Layout;
const { Text, Title } = Typography;

const HomeLog = () => {
  const openLink = (name) => {
    switch (name) {
      case "/hr-v2":
        return (window.location.pathname = "/hr-v2");
      case "/qlct":
        // return (window.location.pathname = "/qlct");
        openNotificationWithIcon("warning", "Chức năng đang phát triển.");
        return false;
      case "/meetingroom":
        // return (window.location.pathname = "/meetingroom");
        openNotificationWithIcon("warning", "Chức năng đang phát triển.");
        return false;
      case "/vac-v2":
        return (window.location.pathname = "/vac-v2");
      case "/iwaki":
        return (window.location.pathname = "/iwaki");
      default:
        break;
    }
  };

  const data_homers = [
    { id: 0, name_link: "/hr-v2", name_show: "HR-V2" },
    { id: 1, name_link: "/qlct", name_show: "QLCT" },
    { id: 2, name_link: "/meetingroom", name_show: "MEETINGROOM" },
  ];

  const data_diff = [
    { id: 0, name_link: "/vac-v2", name_show: "VAC-V2", group_name: "" },
    { id: 1, name_link: "/iwaki", name_show: "IWAKI", group_name: "" },
  ];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout className="site-layout">
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <div
            style={{
              minHeight: 100,
            }}
          >
            <Title level={2}>Log Management System</Title>
          </div>
          <Row justify="center">
            <Col span={4}>
              <div className="site-card-border-less-wrapper">
                <Card
                  title="Homepage Rainscales"
                  bordered={false}
                  style={{
                    width: 300,
                  }}
                >
                  {data_homers.map((item) => (
                    <Button
                      onClick={() => openLink(item.name_link)}
                      style={{
                        width: 200,
                        height: 60,
                        marginRight: "1%",
                        marginBottom: "5%",
                      }}
                    >
                      {item.name_show}
                    </Button>
                  ))}
                </Card>
              </div>
            </Col>
            <Col span={4}>
              <div className="site-card-border-less-wrapper">
                <Card
                  title="Products"
                  bordered={false}
                  style={{
                    width: 300,
                  }}
                >
                  {data_diff.map((item) => (
                    <Button
                      onClick={() => openLink(item.name_link)}
                      style={{
                        width: 200,
                        height: 60,
                        marginRight: "1%",
                        marginBottom: "5%",
                      }}
                    >
                      {item.name_show}
                    </Button>
                  ))}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default HomeLog;
