import { notification } from "antd";
import moment from 'moment';

export function openNotificationWithIcon(type, message, description) {
  notification.config({
    placement: "topRight",
    duration: 1.5,
  });
  notification[type]({
    message: message,
    description: description
  });
};

export const formatDate = (dateString) => {
  const date = moment(dateString);
  const formattedDate = date.format('DD/MM/YYYY HH:mm:ss');
  const microseconds = dateString.split('.')[1];
  return `${formattedDate}.${microseconds}`;
};

 export const disabledDates = (current, dates) => {
    if (!dates) {
      return false;
    }
    const tooLate =
      dates[0] && current.diff(dates[0], "days") >= 366;
    const tooEarly =
      dates[1] && dates[1].diff(current, "days") >= 366;
    return !!tooEarly || !!tooLate;
  };
