import axios from "axios";
import {
    endpoint,
} from "../constants";

const authAxios_v2 = (token, endpoint) => {
    const axiosTest = axios.create({
          baseURL: endpoint,
          headers: {
            Authorization: `Bearer ${token}`,
          }
    });
    axiosTest.interceptors.response.use(response => {
        return response;
    },
    );

    return axiosTest;
};



export default authAxios_v2;
